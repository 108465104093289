import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  getQueryParamNames,
  isOriginInUse,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, LayoutSingleColumn, Page, SearchFilterModal } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import { getConfigData } from '../../util/dataExtractor';
import { isArrayLength } from '../../util/genericHelpers';
import { sortByPriority } from './searchPageHelpers';
import { COSTUMES } from '../../constants';
import IconGrid from '../../components/IconGrid/IconGrid';
import IconList from '../../components/IconList/IconList';

// Define enum for view options
const ViewOptions = {
  GRID: 'grid',
  LIST: 'list',
};
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      searchFilterModalIsOpen: false,
      selectedCategory: [COSTUMES],
      currentView: ViewOptions.GRID,
    };

    this.toggleSearchFilterModal = this.toggleSearchFilterModal.bind(this);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }
  onChangeSearchView(type) {
    this.setState({ currentView: type });
  }
  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }
  toggleSearchFilterModal() {
    this.setState(prevState => ({
      searchFilterModalIsOpen: !prevState.searchFilterModalIsOpen,
    }));
  }
  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: updatedListingConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(updatedListingConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const { categoryConfig, listingFieldsConfig } = getConfigData(
      config,
      this.state.selectedCategory
    );
    const updatedListingConfig = [...categoryConfig, ...listingFieldsConfig];
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            updatedListingConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      routeConfiguration,
      config,
      history,
    } = this.props;

    const iconData = [
      { view: ViewOptions.GRID, icon: <IconGrid className={css.gridIcon} /> },
      { view: ViewOptions.LIST, icon: <IconList className={css.listIcon} /> },
    ];

    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const { categoryConfig, listingFieldsConfig } = getConfigData(
      config,
      this.state.selectedCategory
    );
    const updatedListingConfig = [...categoryConfig, ...listingFieldsConfig];
    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      updatedListingConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      updatedListingConfig,
      defaultFiltersConfig,
      false
    );

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      updatedListingConfig,
      activeListingTypes
    );
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];
    const sortedAvailableFilters =
      isArrayLength(availableFilters) && sortByPriority(availableFilters);
    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      updatedListingConfig,
      defaultFiltersConfig
    );
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      updatedListingConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const { searchFilterModalIsOpen } = this.state;
    const closeModal = () => {
      this.setState({ searchFilterModalIsOpen: false, selectedCategory: COSTUMES });
      // this.resetAll();
    };

    const handleSelectCategory = val => {
      this.setState({ selectedCategory: val });
    };

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutSingleColumn
          className={css.pageRoot}
          topbar={<TopbarContainer currentSearchParams={urlQueryParams} />}
          footer={<FooterContainer />}
        >
          <div className={css.layoutWrapperContainer}>
            <SearchFilterModal
              id="SearchFilterModal.filters"
              isOpen={searchFilterModalIsOpen}
              onClose={closeModal}
              onManageDisableScrolling={onManageDisableScrolling}
              onOutsideClick={closeModal}
              modalTitle="Filters"
            >
              <div className={css.filterColumnContent}>
                <div className={css.allFilters}>
                  {sortedAvailableFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                        idPrefix="SearchFiltersMobile"
                        className={css.filter}
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={urlQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        liveEdit
                        showAsPopup={false}
                        isDesktop
                        onSelectCategory={val => handleSelectCategory(val)}
                      />
                    );
                  })}
                </div>
                <div className={css.filterFooter}>
                  <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                    <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                  </button>
                </div>
              </div>
            </SearchFilterModal>
            <div className={css.layoutWrapperMain} role="main">
              <div className={css.searchResultContainer}>
                <SearchFiltersMobile
                  className={css.searchFiltersMobileList}
                  history={history}
                  validQueryParams={validQueryParams}
                  listingFieldsConfig={validQueryParams}
                  urlQueryParams={validQueryParams}
                  sortByComponent={sortBy('mobile')}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  showAsModalMaxWidth={MODAL_BREAKPOINT}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onOpenModal={this.onOpenMobileModal}
                  onCloseModal={this.onCloseMobileModal}
                  resetAll={this.resetAll}
                  selectedFiltersCount={selectedFiltersCountForMobile}
                  isMapVariant={false}
                  noResultsInfo={noResultsInfo}
                  initialValues={initialValues(this.props, this.state.currentQueryParams)}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  liveEdit
                  showAsPopup={false}
                >
                  {sortedAvailableFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersMobile.${config.scope || 'built-in'}.${config.key}`}
                        idPrefix="SearchFiltersMobile"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        liveEdit
                        showAsPopup={false}
                        onSelectCategory={val => handleSelectCategory(val)}
                      />
                    );
                  })}
                </SearchFiltersMobile>
                <MainPanelHeader
                  className={css.mainPanel}
                  sortByComponent={sortBy('desktop')}
                  history={history}
                  validQueryParams={validQueryParams}
                  isSortByActive={sortConfig.active}
                  listingsAreLoaded={listingsAreLoaded}
                  resultsCount={totalItems}
                  searchInProgress={searchInProgress}
                  searchListingsError={searchListingsError}
                  noResultsInfo={noResultsInfo}
                  onToggleModal={this.toggleSearchFilterModal}
                  idPrefix="SearchFiltersWeb"
                  intl={intl}
                  initialValues={initialValues(this.props, this.state.currentQueryParams)}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  liveEdit
                  showAsPopup={false}
                  pageView={
                    <div className={css.changeCardView}>
                      {iconData.map(item => (
                        <span
                          key={item.view}
                          className={classNames(css.icon, {
                            [css.active]: this.state.currentView === item.view,
                          })}
                          onClick={() => this.onChangeSearchView(item.view)}
                        >
                          {item.icon}
                        </span>
                      ))}
                    </div>
                  }
                />
                <div
                  className={classNames(css.listingsForGridVariant, {
                    [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                    [css.listViewWrapper]: this.state.currentView === ViewOptions.LIST,
                  })}
                >
                  {searchListingsError ? (
                    <H3 className={css.error}>
                      <FormattedMessage id="SearchPage.searchError" />
                    </H3>
                  ) : null}
                  {!isValidDatesFilter ? (
                    <H5>
                      <FormattedMessage id="SearchPage.invalidDatesFilter" />
                    </H5>
                  ) : null}
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    isMapVariant={false}
                    isListView={this.state.currentView === ViewOptions.LIST}
                  />
                </div>
              </div>
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
};

SearchPageComponent.propTypes = {
  listings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
